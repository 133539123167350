
import * as React from 'react'
import { graphql } from 'gatsby'
import { extractFilterProductAttribute, extractFilterVariantAttribute } from '../../config/helper/reducers'
import { ProductsPagined } from '../components/specific/TemplateProducts/ProductsPagined'

/**
 * Template de génération des sous catégory avec pagination
 */

export const query = graphql`

query PaginatedCategoryQuery($categoryId: ID, $categoryIdString: String, $ids:[ID]) {
  allStrapiCategorieContent(
    filter: {categorie: {saleor_id: {eq: $categoryIdString}}}
  ) {
    edges {
      node {
        description {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
        contenuSEO {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
        titre_lien_bas_page
        contenu_liens_haut_de_page {
          name
          saleor_id
          slug
        }
        contenu_liens_bas_de_page {
          name
          saleor_id
          slug
        }
        faq {
          question {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
          reponse {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
        }
        id
      }
    }
  }
  saleor {
    products(first: 60, filter: {ids: $ids}) {
      totalCount
      edges {
        node {
          ...fragProduct
          thumbnail(size: 512) {
            url
            alt
          }
        }
      }
    }
    category(id: $categoryId) {     
      level
      slug
      name
      seoTitle
      seoDescription
      ...categoryChildFrag
    }
  }
}

fragment fragCategoryFromProduct on SALEOR_Category {
  id
  name
  slug
  level
  parent {
    id
    name
    slug
    level
    parent {
      id
      name
      slug
      level
    }
  }
}

fragment fragProduct on SALEOR_Product {
  id
  name
  slug
  variants {
    metadata {
      key
      value
    }
    attributes {
      attribute {
        name
        slug
      }
      values {
        name
        slug
      }
    }
  }
  category {
    ...fragCategoryFromProduct
  }
  metadata {
    key
    value
  }
  seoTitle
  seoDescription
  isAvailable
  availableForPurchase
  isAvailableForPurchase
  pricing {
    onSale
    discount {
      net {
        amount
        currency
      }
    }
    priceRangeUndiscounted {
      start {
        gross {
          amount
        }
      }
    }
    priceRange {
      start {
        gross {
          amount
          currency
        }
      }
    }
  }
  attributes {
    values {
      name
      slug
    }
    values {
      name
      slug
    }
    attribute {
      name
      slug
    }
  }
}

`
interface PageQuery {
  allStrapiCategorieContent: { edges: { node: any }[] }; saleor: { products: { edges: any; pageInfo: any; totalCount: any }; category: any }
}

function extractData(data: PageQuery) {

  let result = {}
  try {

    result = data.allStrapiCategorieContent.edges.map(
      ({ node }) => {

        return {

          description: node.description?.data?.childMarkdownRemark.html,
          contenuSEO:
            node.contenuSEO?.data?.childMarkdownRemark.html,

          liens_haut_page: node.contenu_liens_haut_de_page?.map(({ name, slug }) => ({ name, slug })),
          liens_bas_page: node.contenu_liens_bas_de_page?.map(({ name, slug }) => ({ name, slug })),
          faq: node.faq?.map(({ question, reponse }) => ({ "question": question?.data.childMarkdownRemark.html, "reponse": reponse?.data.childMarkdownRemark.html })),
          titre_lien_bas_page: node.titre_lien_bas_page,

        }
      }
    )[0]
    const { edges, pageInfo, totalCount } = data.saleor.products
    const category = data.saleor.category
    const products = edges?.map(({ node }) => node)
    result = { ...result, ...pageInfo, totalCount, products, category }

  } catch (error) {
    console.warn("some data from CollectionContent are missing", error)
  }

  return result as {
    description: string,
    contenuSEO: string,
    titre_lien_bas_page: string,
    liens_haut_page: any,
    liens_bas_page: any,
    faq: { question: string, reponse: string },
    totalCount: number,

  }


}
const PaginatedSubCategory = ({ pageContext, data }: { pageContext: any, data: PageQuery }) => {


  // calcul de la liste d'id de productType a utiliser pour chercher les filtres
  let attributesValableFromProduct =
    data.saleor.products.edges.reduce(
      extractFilterProductAttribute,
      {}
    )
  let attributesValableFromVariant =
    data.saleor.products.edges.reduce(
      extractFilterVariantAttribute,
      {}
    )

  pageContext = {
    ...pageContext,
    ...extractData(data),
    ... {
      filters: {
        ...attributesValableFromProduct,
        ...attributesValableFromVariant,
      }
    }
  }


  return <ProductsPagined pageContext={pageContext} data={data} />



}



export default PaginatedSubCategory
